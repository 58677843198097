import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize, switchMap, filter, take } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { TokenService } from '../services/token.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    isRefreshingToken = false;

    constructor(private tokenService: TokenService, private toastCtrl: ToastController) {
        this.tokenService.loadToken()
    }

    // Intercept every HTTP call
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // this.tokenService.loadToken()
        if (this.isInBlockedList(request.url)) {
            return next.handle(request);
        } else {

            return next.handle(this.addToken(request)).pipe(
                catchError(err => {
                    if (err instanceof HttpErrorResponse) {
                        switch (err.status) {
                            case 403:
                                return this.handle403Error(err);
                            case 401:
                                return this.handle401Error(request, next);
                            default:
                                return throwError(err);
                        }
                    } else {
                        return throwError(err);
                    }
                })
            );
        }
    }
    // updateMultiModeUids

    // Filter out URLs where you don't want to add the token!
    private isInBlockedList(url: string): Boolean {
        // Example: Filter out our login and logout API call
        if (url == `https://content.dropboxapi.com/2/files/upload`|| url==`https://api.dropboxapi.com/2/sharing/create_shared_link_with_settings` || url == 'https://content.dropboxapi.com/2/files/download' || url==`https://fir-test-4c6bf-default-rtdb.firebaseio.com/clawmachine.json`) {
            return true;
        } else {
            return false;
        }
    }

    // Add our current access token from the service if present
    private addToken(req: HttpRequest<any>) {
        if (this.tokenService.currentAccessToken) {
            return req.clone({
                headers: new HttpHeaders({
                    Authorization: `Bearer ${this.tokenService.currentAccessToken}`
                })
            });
        } else {
            return req;
        }
    }

    // We are not just authorized, we couldn't refresh token
    // or something else along the caching went wrong!
    private async handle403Error(err) {
        // Potentially check the exact error reason for the 400
        // then log out the user automatically
        const toast = await this.toastCtrl.create({
            message: 'Logged out due to authentication mismatch',
            duration: 2000
        });

        toast.present();
        this.tokenService.logout();
        return of(null);
    }

    // Indicates our access token is invalid, try to load a new one
    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // Check if another call is already using the refresh logic
        if (!this.isRefreshingToken) {

            this.tokenSubject.next(null);
            this.isRefreshingToken = true;
            this.tokenService.currentAccessToken = null;

            // First, get a new access token
            return this.tokenService.refreshToken().pipe(
                switchMap((token: any) => {
                    if (token.accessToken && token.success == 1) {
                        // Store the new token
                        const accessToken = token.accessToken;

                        return this.tokenService.storeAccessToken(accessToken).pipe(
                            switchMap(_ => {
                                // Use the subject so other calls can continue with the new token
                                this.tokenSubject.next(accessToken);
                                // Perform the initial request again with the new token
                                return next.handle(this.addToken(request));

                            })
                        );
                    } else {
                        // No new token or other problem occurred
                        this.tokenService.logout()
                        return of(null);
                    }
                }),
                finalize(() => {
                    // Unblock the token reload logic when everything is done
                    this.isRefreshingToken = false;
                })
            );
        } else {
            // "Queue" other calls while we load a new token
            return this.tokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(token => {
                    // Perform the request again now that we got a new token!
                    return next.handle(this.addToken(request));
                })
            );
        }
    }

}