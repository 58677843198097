export const environment = {
  production: true
};

export const baseUrl = 'https://sso.vsing.tech'

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyARRRjxcrVQPT8DFq1AQGoFG3ESz4S8Zfs",
  authDomain: "v-sing.firebaseapp.com",
  databaseURL: "https://v-sing.firebaseio.com",
  projectId: "v-sing",
  storageBucket: "v-sing.appspot.com",
  messagingSenderId: "689283233795",
  appId: "1:689283233795:web:5cf2eb4b88c5489e",
  measurementId: "G-6E6J7XNWJH"
}

