import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { baseUrl } from 'src/environments/environment';
import { NavController } from '@ionic/angular';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<any>(null);
  currentAccessToken = localStorage.getItem('VSINGADMIN_ACCESS_TOKEN_KEY');

  constructor(private http: HttpClient, private nav: NavController, private dataService : DataService) {

  }

  // Load accessToken on startup
  async loadToken() {
    const token: any = await localStorage.getItem('VSINGADMIN_ACCESS_TOKEN_KEY');
    if (token) {
      this.currentAccessToken = token;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }


  storeToken(accessToken, refreshToken) {
    this.currentAccessToken = accessToken;
    localStorage.setItem('VSINGADMIN_ACCESS_TOKEN_KEY', accessToken)
    localStorage.setItem('VSINGADMIN_REFRESH_TOKEN_KEY', refreshToken)
    this.isAuthenticated.next(true);
  }

  logout() {
    localStorage.removeItem('VSINGADMIN_ACCESS_TOKEN_KEY')
    localStorage.removeItem('VSINGADMIN_REFRESH_TOKEN_KEY')
    this.isAuthenticated.next(false);
    this.nav.navigateRoot('login', { animated: true, animationDirection: 'back' })
  }

  // Load the refresh token from storage
  // then attach it as the header for one specific API call
  refreshToken() {
    const refreshToken: any = from([localStorage.getItem('VSINGADMIN_REFRESH_TOKEN_KEY')]);

    return refreshToken.pipe(
      switchMap((token: any) => {
        if (token) {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            })
          }

          return this.http.post(`${baseUrl}/admin/refreshAdminToken`, {}, httpOptions);
        } else {
          // No stored refresh token
          return of(null);
        }
      })
    );
  }

  // Store a new access token
  storeAccessToken(accessToken: any) {
    this.currentAccessToken = accessToken
    localStorage.setItem('VSINGADMIN_ACCESS_TOKEN_KEY', this.currentAccessToken)
    return from([localStorage.setItem('VSINGADMIN_ACCESS_TOKEN_KEY', this.currentAccessToken)] as any);
  }
}