 import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'tabs',
    redirectTo: 'tabs/onlinesales',
    pathMatch: 'full'
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.routes').then( m => m.routes)
  },
  {
    path: 'login',
    loadComponent: () => import('./login/login.page').then( m => m.LoginPage)
  },
  {
    path: 'vgemsales',
    loadComponent: () => import('./vgemsales/vgemsales.page').then( m => m.VgemsalesPage)
  },
  {
    path: 'saleonline',
    loadComponent: () => import('./saleonline/saleonline.page').then( m => m.SaleonlinePage)
  },
  {
    path: 'onlinesales',
    loadComponent: () => import('./onlinesales/onlinesales.page').then( m => m.OnlinesalesPage)
  },
  {
    path: 'resellertopup',
    loadComponent: () => import('./resellertopup/resellertopup.page').then( m => m.ResellertopupPage)
  },
  {
    path: 'vgoffset',
    loadComponent: () => import('./inapptransaction/vgoffset/vgoffset.page').then( m => m.VgoffsetPage)
  },
  {
    path: 'userbase',
    loadComponent: () => import('./userbase/userbase.page').then( m => m.UserbasePage)
  },
  {
    path: 'withdraw',
    loadComponent: () => import('./withdraw/withdraw.page').then( m => m.WithdrawPage)
  },
  {
    path: 'vpexpiry',
    loadComponent: () => import('./vpexpiry/vpexpiry.page').then( m => m.VpexpiryPage)
  },
  {
    path: 'songhistory',
    loadComponent: () => import('./songhistory/songhistory.page').then( m => m.SonghistoryPage)
  },
  {
    path: 'vptovgem',
    loadComponent: () => import('./vgemgeneration/vptovgem/vptovgem.page').then( m => m.VptovgemPage)
  },
  {
    path: 'outlettransfer',
    loadComponent: () => import('./vgemgeneration/outlettransfer/outlettransfer.page').then( m => m.OutlettransferPage)
  },
  {
    path: 'admintransfer',
    loadComponent: () => import('./vgemgeneration/admintransfer/admintransfer.page').then( m => m.AdmintransferPage)
  },
  {
    path: 'newuserfreevgem',
    loadComponent: () => import('./vgemgeneration/newuserfreevgem/newuserfreevgem.page').then( m => m.NewuserfreevgemPage)
  },
  {
    path: 'pincodefreevgem',
    loadComponent: () => import('./vgemgeneration/pincodefreevgem/pincodefreevgem.page').then( m => m.PincodefreevgemPage)
  },
  {
    path: 'referralfreevgem',
    loadComponent: () => import('./vgemgeneration/referralfreevgem/referralfreevgem.page').then( m => m.ReferralfreevgemPage)
  },
  {
    path: 'friendshipfreevgem',
    loadComponent: () => import('./vgemgeneration/friendshipfreevgem/friendshipfreevgem.page').then( m => m.FriendshipfreevgemPage)
  },
  {
    path: 'globalvoucher',
    loadComponent: () => import('./voucher/globalvoucher/globalvoucher.page').then( m => m.GlobalvoucherPage)
  },
  {
    path: 'globalvoucher-edit',
    loadComponent: () => import('./voucher/globalvoucher-edit/globalvoucher-edit.page').then( m => m.GlobalvoucherEditPage)
  },
  {
    path: 'globalvoucher-create',
    loadComponent: () => import('./voucher/globalvoucher-create/globalvoucher-create.page').then( m => m.GlobalvoucherCreatePage)
  },
  {
    path: 'vpproducts',
    loadComponent: () => import('./Market/vpproducts/vpproducts.page').then( m => m.VpproductsPage)
  },
  {
    path: 'autogifting',
    loadComponent: () => import('./AutoGift/autogifting/autogifting.page').then( m => m.AutogiftingPage)
  },
  {
    path: 'autogiftsetting',
    loadComponent: () => import('./AutoGift/autogiftsetting-create/autogiftsetting-create.page').then( m => m.AutogiftsettingCreatePage)
  },
  {
    path: 'autogiftsetting-create',
    loadComponent: () => import('./AutoGift/autogiftsetting-create/autogiftsetting-create.page').then( m => m.AutogiftsettingCreatePage)
  },
  {
    path: 'autogiftsetting-edit',
    loadComponent: () => import('./AutoGift/autogiftsetting-edit/autogiftsetting-edit.page').then( m => m.AutogiftsettingEditPage)
  },
  {
    path: 'autogiftuser',
    loadComponent: () => import('./AutoGift/autogiftuser/autogiftuser.page').then( m => m.AutogiftuserPage)
  },
  {
    path: 'referral-create',
    loadComponent: () => import('./Referral/referral-create/referral-create.page').then( m => m.ReferralCreatePage)
  },
  {
    path: 'referral-edit',
    loadComponent: () => import('./Referral/referral-edit/referral-edit.page').then( m => m.ReferralEditPage)
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./Dashboard/dashboard/dashboard.page').then( m => m.DashboardPage)
  },
  {
    path: 'songrecord',
    loadComponent: () => import('./VGEMTransaction/songrecord/songrecord.page').then( m => m.SongrecordPage)
  },
  {
    path: 'gift-categories',
    loadComponent: () => import('./gifts/gift-categories/gift-categories.page').then( m => m.GiftCategoriesPage)
  },
  {
    path: 'giftcategories-edit',
    loadComponent: () => import('./gifts/giftcategories-edit/giftcategories-edit.page').then( m => m.GiftcategoriesEditPage)
  },
  {
    path: 'giftcategories-create',
    loadComponent: () => import('./gifts/giftcategories-create/giftcategories-create.page').then( m => m.GiftcategoriesCreatePage)
  },
  {
    path: 'productcategory-create',
    loadComponent: () => import('./products/productcategory-create/productcategory-create.page').then( m => m.ProductcategoryCreatePage)
  },
  {
    path: 'productcategory-edit',
    loadComponent: () => import('./products/productcategory-edit/productcategory-edit.page').then( m => m.ProductcategoryEditPage)
  },
  {
    path: 'topup-vgem',
    loadComponent: () => import('./Outlet/topup-vgem/topup-vgem.page').then( m => m.TopupVgemPage)
  },
  {
    path: 'outlet-wallet',
    loadComponent: () => import('./Outlet/outlet-wallet/outlet-wallet.page').then( m => m.OutletWalletPage)
  },
  {
    path: 'giftrecord',
    loadComponent: () => import('./giftrecord/giftrecord.page').then( m => m.GiftrecordPage)
  },
  {
    path: 'user-list',
    loadComponent: () => import('./user-list/user-list.page').then( m => m.UserListPage)
  }
];
