import { Component, EnvironmentInjector, inject } from '@angular/core';
import { IonicModule, NavController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { baseUrl, FIREBASE_CONFIG } from 'src/environments/environment';
import firebase from 'firebase';
import { AccountingService } from './accounting.service';
import { DataService } from './services/data.service';
import { BentoService } from './services/bento.service';
import { HttpClient } from '@angular/common/http';

// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class AppComponent {
  public environmentInjector = inject(EnvironmentInjector);

  constructor(
    private accounting: AccountingService, 
    private dataService: DataService,
    private bentoService: BentoService,
    private http : HttpClient,
    private nav: NavController) {


    this.dataService.updateLanguage(localStorage.getItem('VSINGADMIN_LANGUAGE') || 'English')

    // firebase.initializeApp(FIREBASE_CONFIG)
    const url = window.location.href.split('/').pop()

    this.http.post(baseUrl + '/admin/getGeneralSettings', {}).subscribe((res) => {
      if(res['success'] == true){
        this.dataService.updateSettings(res['setting'])
      }
    })
    
    if(!this.bentoService.isNullOrEmpty(localStorage.getItem('VSINGADMIN_ACCESS_TOKEN_KEY')) && !this.bentoService.isNullOrEmpty(localStorage.getItem('VSINGADMIN_REFRESH_TOKEN_KEY'))){
      // this.nav.navigateRoot('tabs/tab1', { animationDirection: "forward" })

      this.http.post(baseUrl + '/admin/getFranchises', { country: 'china' }).subscribe((res) => {
        if(res['success'] == true){
          this.dataService.updateFranchises(res['data'])
        }
      })

      this.http.post(baseUrl + '/admin/getAdminData', { }).subscribe((res) => {
        console.log(res)
        this.dataService.updateUser(res['data'])
        // this.dataService.updateUser
      })
    }




    // firebase.auth().onAuthStateChanged(a => {

    //   if (a) {

    //     firebase.database().ref('settings/languages').on('value', lang => {
    //       this.accounting.updateLanguage(Object.values(lang.val()))
    //     })

    //     firebase.database().ref('admin3/' + a.uid).once('value', acc => {

    //       if (acc.exists()) {
    //         this.accounting.updateUserInfo(acc.val())

    //         firebase.database().ref('franchises').orderByChild('country').equalTo(acc.val()['country']).once('value', branch => {
    //           this.accounting.updatebranchinfo(Object.values(branch.val()))
    //         })

    //         firebase.database().ref('countries/' + acc.val()['country'] + '/settings').once('value', data => {
    //           this.accounting.updateSetting((data.val()))
    //         })

    //       } else {

    //         firebase.auth().signOut().then(() => {
    //           this.nav.navigateRoot('login', { animationDirection: "forward" });
    //         })

    //       }

    //     })

    //   } else {

    //     // firebase.auth().signOut().then(() => {
    //     this.nav.navigateRoot('login', { animationDirection: "forward" });
    //     // })

    //   }



    // })


  }

}
