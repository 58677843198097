import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class BentoService {

  constructor(
    private http: HttpClient
  ) { }

  isNullOrEmpty(...args: any[]) {

    let temp = args[0]

    if (temp === undefined || temp === null || temp === '') {
      return true
    }

    if (typeof temp === 'string') {
      if (temp === undefined || temp === null || temp.trim() === '') {
        return true
      }

      return false
    }

    if (typeof temp === 'object' && !Array.isArray(temp)) {
      let variable = args[1]

      for (let i = 0; i < variable.length; i++) {
        if (temp[variable[i]] === undefined || temp[variable[i]] === null || temp[variable[i]] === '') {
          return true;
        }
      }

      return false;

    }

    if (typeof temp === 'object' && Array.isArray(temp)) {
      let variable = args[1]

      for (let t = 0; t < temp.length; t++) {
        for (let i = 0; i < variable.length; i++) {
          if (temp[t][variable[i]] === undefined || temp[t][variable[i]] === null || temp[t][variable[i]] === '') {
            return true;
          }
        }
      }

      return false;

    }

    return true
  }

  emailValidator(email: any) {
    if (email) {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    } else {
      return false;
    }
  }


  errorMsg(x: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
    })

    Toast.fire({
      icon: 'error',
      title: x,
    })
  }

  fileChange(event, watermarkRequire = false) {
    return new Promise((resolve, reject) => {
      if (event.target.files && event.target.files[0] && event.target.files[0].size < (10485768)) {
        var canvas = <HTMLCanvasElement>document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var cw = canvas.width;
        var ch = canvas.height;
        var maxW = 400;
        var maxH = 400;
        var type = event.target.files[0].type;
        var img = new Image;
        img.onload = () => {
          var iw = img.width;
          var ih = img.height;
          var scale = Math.min((maxW / iw), (maxH / ih));
          var iwScaled = iw * scale;
          var ihScaled = ih * scale;
          canvas.width = iwScaled;
          canvas.height = ihScaled;
          ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
          event.target.value = ''


          let imagec = canvas.toDataURL();
          let imgggg = imagec.replace(';base64,', "thisisathingtoreplace;")
          let imgarr = imgggg.split("thisisathingtoreplace;")
          let base64String = imgarr[1]

          resolve({ success: true, data: { image: imagec, base64String: base64String } })
        }


        // if (watermarkRequire == true) {
        //   const options = {
        //     init(img) {
        //       img.corssOrigin = 'anonymous'
        //     }
        //   }

        //   watermark([event.target.files[0]], options)
        //     .image(watermark.text.center('For VSING Sing And\n Earn Purpose Only', '40px serif', '#fff', 1))
        //     .then(watermarkedImg => {
        //       img.src = watermarkedImg.src;
        //   })


        // } else {
        img.src = URL.createObjectURL(event.target.files[0]);
        // }

      } else {
        reject({ success: false, message: '"Your Current Image Too Large, " + event.target.files[0].size / (10241024) + "MB! (Please choose file lesser than 8MB)"' })
        alert("Your Current Image Too Large, " + event.target.files[0].size / (10241024) + "MB! (Please choose file lesser than 8MB)")
      }
    })
  }

  pictureToLink(image, userid) {
    return new Promise((resolve, reject) => {
      this.http.post('https://0w4xkg8ca0.execute-api.ap-southeast-1.amazonaws.com/dev/upload', { image: image, folder: 'vsing', userid: (userid + Date.now()) }, { observe: 'response' }).subscribe((res) => {
        if (res['status'] === 200) {
          resolve({ success: true, link: res['body']['imageURL'] })
        }

        if (res['status'] !== 200)
          reject({ success: false, message: 'Something went wrong!' })
      }, error => {
        reject({ success: false, message: error['message'] })
      })
    })
  }

  showMessage(title, text, icon) {
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
      heightAuto: false,
    })
  }

  swalButton(title) {
    return new Promise((resolve) => {
      Swal.fire({
        title: title,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        heightAuto: false,
      }).then((result) => {
        resolve(result)
      })
    })
  }

  getTemporaryLink(path) {
    return new Promise((resolve) => {
      this.http.post(baseUrl + '/admin/getDropboxLink', { path: path }).subscribe(async res => {
        if(res['success'] == true){
          resolve(res['link'])
        }else{
          resolve('')
        }
        
      }, error => {
        resolve('')
      })
    })
  }

  getDropboxFolder(path) {
    return new Promise((resolve) => {
      this.http.post(baseUrl + '/admin/getDropboxFolder', { path: path }).subscribe(async res => {
        console.log(res)
        if(res['success'] == true){
          resolve(res['data'])
        }else{
          resolve('')
        }
        
      }, error => {
        resolve('')
      })
    })
  }


  createDropboxFolder(data) {
    return new Promise((resolve) => {
      this.http.post(baseUrl + '/admin/createDropboxFolder', data).subscribe(async res => {
        console.log(res)
        if(res['success'] == true){
          resolve(res['data'])
        }else{
          resolve('')
        }
        
      }, error => {
        resolve('')
      })
    })
  }

  createFileRequest(data) {
    return new Promise((resolve) => {
      this.http.post(baseUrl + '/admin/createFileRequest', data).subscribe(async res => {
        console.log(res)
        resolve(res['url'])
        
      }, error => {
        resolve('')
      })
    })
  }


  
 
}
