import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private userSource = new BehaviorSubject({});
  userInfo = this.userSource.asObservable();

  private languageSource = new BehaviorSubject('English');
  language = this.languageSource.asObservable();

  private settingSource = new BehaviorSubject({});
  setting = this.settingSource.asObservable();

  private franchisesSource = new BehaviorSubject([]);
  franchises = this.franchisesSource.asObservable();

  private countrySorce = new BehaviorSubject([]);
  country = this.countrySorce.asObservable();

  constructor() { }

  updateUser(info: object) {
    this.userSource.next(info)
  }

  updateSettings(info: object){
    this.settingSource.next(info)
  }

  updateFranchises(info){
    this.franchisesSource.next(info)
  }


  updateLanguage(info) {
    localStorage.setItem('VSINGADMIN_LANGUAGE', info)
    this.languageSource.next(info)
  }

  updateCountry(info){
    this.countrySorce.next(info)
  }

  
}
